import React, { Component } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { getSession } from './views/utils/useSessionStorage'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './scss/style.scss'
import './scss/styles.css'
import './scss/apps.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./Login'))
class App extends Component {
  render() {
    const auth = JSON.parse(getSession('isAuthenticated'))

    //console.log(auth)

    return (
      <Router>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            {/* <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </React.Suspense>
      </Router>
    )
  }
}

export default App
